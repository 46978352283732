import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Autocomplete, Avatar, Box, ButtonBase, Card, Grid, InputAdornment, OutlinedInput, Popper, TextField } from '@mui/material';

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from '@tabler/icons';
import { shouldForwardProp } from '@mui/system';
import { useEffect } from 'react';

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
    zIndex: 1100,
    width: '99%',
    top: '-55px !important',
    padding: '0 12px',
    [theme.breakpoints.down('sm')]: {
        padding: '0 10px'
    }
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    width: 434,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('lg')]: {
        width: 250
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 4,
        background: '#fff'
    }
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    '&:hover': {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light
    }
}));

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ dataStore, handleOnChangeSelect, valueStore }) => {
    const theme = useTheme();

    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={dataStore}
            sx={{ width: '100%' }}
            onChange={(e, val) => setStore(val)}
            value={valueStore}
            renderInput={(params) => <TextField name="name" {...params} />}
        />
    );
};

MobileSearch.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    popupState: PopupState
};

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = ({ handleOnChangeSelect, valueStore, setStore, dataStore }) => {
    const theme = useTheme();
    const [value, setValue] = useState('');

    return (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <PopupState variant="popper" popupId="demo-popup-popper">
                    {(popupState) => (
                        <>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={dataStore}
                                sx={{ width: 200, marginLeft: 2 }}
                                onChange={(e, val) => setStore(val)}
                                value={valueStore}
                                renderInput={(params) => <TextField name="name" {...params} />}
                            />
                        </>
                    )}
                </PopupState>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={dataStore}
                    sx={{ width: 300, marginInline: 4 }}
                    onChange={(e, val) => setStore(val)}
                    value={valueStore}
                    renderInput={(params) => (
                        <TextField onChange={(val) => handleOnChangeSelect(val)} name="name" {...params} label="Pilih Toko" />
                    )}
                />
            </Box>
        </>
    );
};

export default SearchSection;
