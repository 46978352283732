/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import MidleWareAuth from './MidlewareAuth';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// MASTER
const MasterStoreServer = Loadable(lazy(() => import('views/pages/master/store-server/index')));
const MasterAccessRight = Loadable(lazy(() => import('views/pages/master/accessRights')));
// REPORT MASTER
const ReportMasterItem = Loadable(lazy(() => import('views/pages/report/master/item/index')));
const ReportMasterUnit = Loadable(lazy(() => import('views/pages/report/master/unit')));
const ReportMasterType = Loadable(lazy(() => import('views/pages/report/master/type')));
const ReportMasterBrand = Loadable(lazy(() => import('views/pages/report/master/brand')));
const ReportMasterMember = Loadable(lazy(() => import('views/pages/report/master/member')));
const ReportMasterSupplier = Loadable(lazy(() => import('views/pages/report/master/supplier')));
// REPORT PURCHASE
const ReportPurchaseOrders = Loadable(lazy(() => import('views/pages/report/purchase/purchaseOrders')));
const ReportPurchases = Loadable(lazy(() => import('views/pages/report/purchase/purchases')));
const ReportPurchaseReturns = Loadable(lazy(() => import('views/pages/report/purchase/purchasesReturns')));
const ReportPurchasePerItems = Loadable(lazy(() => import('views/pages/report/purchase/purchasePerItems')));
// REPORT SALES
const ReportSalesOrders = Loadable(lazy(() => import('views/pages/report/sale/salesOrders')));
const ReportSalesOrderPerItems = Loadable(lazy(() => import('views/pages/report/sale/salesOrderPerItems')));
const ReportSales = Loadable(lazy(() => import('views/pages/report/sale/sales')));
const ReportSalePerItems = Loadable(lazy(() => import('views/pages/report/sale/salesPerItems')));
const ReportSaleCashiers = Loadable(lazy(() => import('views/pages/report/sale/salesCashiers')));
const ReportSaleCashierPerItems = Loadable(lazy(() => import('views/pages/report/sale/salesCashierPerItems')));
const ReportSaleReturns = Loadable(lazy(() => import('views/pages/report/sale/salesReturns')));
const ReportSaleReturnsPerItems = Loadable(lazy(() => import('views/pages/report/sale/salesReturnPerItems')));
const ReportBestSellerPerItems = Loadable(lazy(() => import('views/pages/report/sale/bestSeller')));
// REPORT DEBT
const ReportOutstandingDebt = Loadable(lazy(() => import('views/pages/report/debt/outstandingDebts')));
const ReportOutstandingDebtDues = Loadable(lazy(() => import('views/pages/report/debt/outstandingDebtDues')));
const ReportOutstandingDebtPersuppliers = Loadable(lazy(() => import('views/pages/report/debt/outstandingDebtPersuppliers')));
const ReportDebtPayments = Loadable(lazy(() => import('views/pages/report/debt/debtPayments')));
//REPORT DEBT MEMBER
const ReportDebtPaymentMembers = Loadable(lazy(() => import('views/pages/report/debtMember/debtPaymentMembers')));
const ReportOutstandingDebtMembers = Loadable(lazy(() => import('views/pages/report/debtMember/outstandingDebtMembers')));
const ReportOutstandingDebtDueMembers = Loadable(lazy(() => import('views/pages/report/debtMember/outstandingDebtDueMembers')));
const ReportOutstandingDebtPermembers = Loadable(lazy(() => import('views/pages/report/debtMember/outstandingDebtPermembers')));
// REPORT SUPPLY
const ReportItemGetIns = Loadable(lazy(() => import('views/pages/report/supply/itemGetIn')));
const ReportItemOuts = Loadable(lazy(() => import('views/pages/report/supply/itemOut')));
const ReportStockOpnames = Loadable(lazy(() => import('views/pages/report/supply/stockOpname')));
const ReportSItemBeginnings = Loadable(lazy(() => import('views/pages/report/supply/itemBeginning')));
const ReportItemTransfers = Loadable(lazy(() => import('views/pages/report/supply/itemTransfer')));
const ReportItemStockCards = Loadable(lazy(() => import('views/pages/report/supply/itemStockCard')));
const ReportItemStockMutations = Loadable(lazy(() => import('views/pages/report/supply/itemStockMutation')));
const ReportItemExpireds = Loadable(lazy(() => import('views/pages/report/supply/itemExpired')));
const ReportItemStocks = Loadable(lazy(() => import('views/pages/report/supply/itemStock')));
// REPORT PROFIT
const ReportSalesProfits = Loadable(lazy(() => import('views/pages/report/pofit/SalesProfit')));
const ReportAnalisProfits = Loadable(lazy(() => import('views/pages/report/pofit/analisProfit')));
// REPORT KAS
const ReportCashIns = Loadable(lazy(() => import('views/pages/report/cash/cashIn')));
const ReportCashOuts = Loadable(lazy(() => import('views/pages/report/cash/cashOut')));
const ReportCashTransfers = Loadable(lazy(() => import('views/pages/report/cash/cashTransfer')));
// REPORT JOURNAL
const ReportListJournals = Loadable(lazy(() => import('views/pages/report/journal/listJournal')));
const ReportJournalNotBalances = Loadable(lazy(() => import('views/pages/report/journal/journalNotBalance')));
// REPORT LEDGER
const ReportGeneralLedgers = Loadable(lazy(() => import('views/pages/report/ledger/generalLedger')));
const ReportTrialBalances = Loadable(lazy(() => import('views/pages/report/ledger/trialBalance')));
const ReportBalanceSheets = Loadable(lazy(() => import('views/pages/report/ledger/balanceSheet')));
// REPORT FINANCE
const ReportBalances = Loadable(lazy(() => import('views/pages/report/finance/balance')));
const ReportIncomeStatements = Loadable(lazy(() => import('views/pages/report/finance/incomeStatement')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: (
                <MidleWareAuth>
                    <DashboardDefault />
                </MidleWareAuth>
            )
        },
        {
            path: 'dashboard',
            element: (
                <MidleWareAuth>
                    <DashboardDefault />
                </MidleWareAuth>
            )
        },
        {
            path: 'master/store-server',
            element: (
                <MidleWareAuth>
                    <MasterStoreServer />
                </MidleWareAuth>
            )
        },
        {
            path: 'master/access-right',
            element: (
                <MidleWareAuth>
                    <MasterAccessRight />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/master/report-item',
            element: (
                <MidleWareAuth>
                    <ReportMasterItem />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/master/report-unit',
            element: (
                <MidleWareAuth>
                    <ReportMasterUnit />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/master/report-type',
            element: (
                <MidleWareAuth>
                    <ReportMasterType />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/master/report-brand',
            element: (
                <MidleWareAuth>
                    <ReportMasterBrand />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/master/report-member',
            element: (
                <MidleWareAuth>
                    <ReportMasterMember />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/master/report-supplier',
            element: (
                <MidleWareAuth>
                    <ReportMasterSupplier />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/purchase/report-purchase-order',
            element: (
                <MidleWareAuth>
                    <ReportPurchaseOrders />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/purchase/report-purchases',
            element: (
                <MidleWareAuth>
                    <ReportPurchases />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/purchase/report-purchase-returns',
            element: (
                <MidleWareAuth>
                    <ReportPurchaseReturns />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/purchase/report-purchase-peritems',
            element: (
                <MidleWareAuth>
                    <ReportPurchasePerItems />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/sale/report-sales-order',
            element: (
                <MidleWareAuth>
                    <ReportSalesOrders />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/sale/report-sales-order-peritem',
            element: (
                <MidleWareAuth>
                    <ReportSalesOrderPerItems />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/sale/report-sales',
            element: (
                <MidleWareAuth>
                    <ReportSales />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/sale/report-sale-peritems',
            element: (
                <MidleWareAuth>
                    <ReportSalePerItems />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/sale/report-sale-cashiers',
            element: (
                <MidleWareAuth>
                    <ReportSaleCashiers />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/sale/report-sale-cashier-peritems',
            element: (
                <MidleWareAuth>
                    <ReportSaleCashierPerItems />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/sale/report-sale-returns',
            element: (
                <MidleWareAuth>
                    <ReportSaleReturns />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/sale/report-sale-return-peritems',
            element: (
                <MidleWareAuth>
                    <ReportSaleReturnsPerItems />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/sale/report-best-seller',
            element: (
                <MidleWareAuth>
                    <ReportBestSellerPerItems />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/debt/report-debt-outstanding',
            element: (
                <MidleWareAuth>
                    <ReportOutstandingDebt />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/debt/report-debt-outstanding-due',
            element: (
                <MidleWareAuth>
                    <ReportOutstandingDebtDues />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/debt/report-debt-outstanding-persupplier',
            element: (
                <MidleWareAuth>
                    <ReportOutstandingDebtPersuppliers />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/debt/report-debt-payment',
            element: (
                <MidleWareAuth>
                    <ReportDebtPayments />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/receivable/report-receivable-outstanding',
            element: (
                <MidleWareAuth>
                    <ReportOutstandingDebtMembers />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/receivable/report-receivable-outstanding-due',
            element: (
                <MidleWareAuth>
                    <ReportOutstandingDebtDueMembers />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/receivable/report-receivable-outstanding-permember',
            element: (
                <MidleWareAuth>
                    <ReportOutstandingDebtPermembers />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/receivable/report-receivable-payment',
            element: (
                <MidleWareAuth>
                    <ReportDebtPaymentMembers />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/supply/report-item-get-in',
            element: (
                <MidleWareAuth>
                    <ReportItemGetIns />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/supply/report-item-out',
            element: (
                <MidleWareAuth>
                    <ReportItemOuts />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/supply/report-stock-opname',
            element: (
                <MidleWareAuth>
                    <ReportStockOpnames />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/supply/report-item-beginning',
            element: (
                <MidleWareAuth>
                    <ReportSItemBeginnings />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/supply/report-item-transfer',
            element: (
                <MidleWareAuth>
                    <ReportItemTransfers />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/supply/report-stock-card',
            element: (
                <MidleWareAuth>
                    <ReportItemStockCards />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/supply/report-stock-mutation',
            element: (
                <MidleWareAuth>
                    <ReportItemStockMutations />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/supply/report-expired-date',
            element: (
                <MidleWareAuth>
                    <ReportItemExpireds />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/profit/selling-profit',
            element: (
                <MidleWareAuth>
                    <ReportSalesProfits />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/profit/analys-profit',
            element: (
                <MidleWareAuth>
                    <ReportAnalisProfits />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/cash/report-cash-in',
            element: (
                <MidleWareAuth>
                    <ReportCashIns />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/cash/report-cash-out',
            element: (
                <MidleWareAuth>
                    <ReportCashOuts />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/cash/report-cash-transfer',
            element: (
                <MidleWareAuth>
                    <ReportCashTransfers />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/journal/report-list-journal',
            element: (
                <MidleWareAuth>
                    <ReportListJournals />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/journal/report-journal-not-balance',
            element: (
                <MidleWareAuth>
                    <ReportJournalNotBalances />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/ledger/report-general-ledger',
            element: (
                <MidleWareAuth>
                    <ReportGeneralLedgers />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/ledger/report-trial-balance',
            element: (
                <MidleWareAuth>
                    <ReportTrialBalances />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/ledger/report-balance-sheet',
            element: (
                <MidleWareAuth>
                    <ReportBalanceSheets />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/finance/report-income-statement',
            element: (
                <MidleWareAuth>
                    <ReportIncomeStatements />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/finance/report-balance',
            element: (
                <MidleWareAuth>
                    <ReportBalances />
                </MidleWareAuth>
            )
        },
        {
            path: 'report/supply/report-stock',
            element: (
                <MidleWareAuth>
                    <ReportItemStocks />
                </MidleWareAuth>
            )
        }
    ]
};

export default MainRoutes;
