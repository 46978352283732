// assets
import {
    IconReportAnalytics,
    IconDeviceDesktopAnalytics,
    IconShoppingCart,
    IconLogout,
    IconLogin,
    IconClipboardList,
    IconUser
} from '@tabler/icons';

// constant
const icons = {
    IconReportAnalytics,
    IconDeviceDesktopAnalytics,
    IconShoppingCart,
    IconLogout,
    IconLogin,
    IconClipboardList,
    IconUser
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const masterMenu = {
    id: 'master-data',
    title: 'Data Master',
    type: 'group',
    children: [
        {
            id: 'access-right',
            title: 'User',
            type: 'item',
            url: '/master/access-right',
            icon: icons.IconUser
        },
        {
            id: 'store',
            title: 'Toko',
            type: 'item',
            url: '/master/store-server',
            icon: icons.IconReportAnalytics
        }
    ]
};

export default masterMenu;
