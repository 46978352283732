// AUTH
export const storeLogin = 'auth/login';
// MASTER
export const storeServerPost = 'store-server';
export const storeServerGet = 'store-server';
export const storeServerDelete = 'store-server';
export const storeServerGetOne = 'store-server';
export const storeServerPut = 'store-server';
export const storeType = 'product-type';
export const storeSupplier = 'supplier';
export const storeAccessRigthGet = 'access-right';
export const storeAccessRigthPost = 'access-right';
export const storeAccessRigthDelete = 'access-right';
export const storeAccessRigthGetOne = 'access-right';
export const storeAccessRigthPut = 'access-right';
export const storeWarehouse = 'warehouse';
//SELECT
export const storeTypeSelect = 'product-type/select';
export const storeBrandSelect = 'product-brand/select';
export const storeWarehouseSelect = 'warehouse/select';
export const storeMemberLevelSelect = 'member-level/select';
export const storeSupplierSelect = 'supplier/select';
export const storeUserSelect = 'user/select';
export const storeItemSelect = 'product/select';
export const storeMemberSelect = 'member/select';
export const storeServerSelect = 'store-server/select';
export const storeForecastAccountSelect = 'forecast-account/select';
export const storeOwnerSelect = 'access-right/select';
// REPORT MASTER
export const storeReportItem = 'report-item';
export const storeReportUnit = 'report-unit';
export const storeReportType = 'report-type';
export const storeReportBrand = 'report-brand';
export const storeReportMember = 'report-member';
export const storeReportSupplier = 'report-supplier';
// REPORT PURCHASE
export const storeReportPurchaseOrder = 'report-purchase-order';
export const storeReportPurchase = 'report-purchase';
export const storeReportPurchaseReturns = 'report-purchase-return';
export const storeReportPurchasePeritems = 'report-purchase-peritem';
// REPORT SALES
export const storeReportSalesOrder = 'report-sales-order';
export const storeReportSalesOrderPeritems = 'report-sales-order-peritem';
export const storeReportSales = 'report-sale';
export const storeReportSalePeritems = 'report-sale-peritem';
export const storeReportSaleCashiers = 'report-sale-cashier';
export const storeReportSaleCashierPeritems = 'report-sale-cashier-peritem';
export const storeReportSaleReturns = 'report-sales-return';
export const storeReportSaleReturnsPeritems = 'report-sales-return-peritem';
export const storeReportBestSalerPeritems = 'report-best-seller';
// REPORT DEBT SUPPLIER
export const storeReportDebtPayment = 'report-debt-payment';
export const storeReportOutstandingDebt = 'report-outstanding-debt';
export const storeReportOutstandingDebtDues = 'report-outstanding-debt-due';
export const storeReportOutstandingDebtPersuppliers = 'report-outstanding-debt-persupplier';
// REPORT DEBT MEMBER
export const storeReportDebtMember = 'report-debt-payment-member';
export const storeReportOutstandingDebtMembers = 'report-outstanding-debt-member';
export const storeReportOutstandingDebtDueMembers = 'report-outstanding-debt-member-due';
export const storeReportOutstandingDebtPermembers = 'report-outstanding-debt-member-permember';
// REPORT SUPPLY
export const storeReportItemGetIn = 'report-item-get-in';
export const storeItemOut = 'report-item-out';
export const storeReportStockOpname = 'report-item-opname';
export const storeSItemBeginning = 'report-item-beginning';
export const storeItemTransfer = 'report-item-transfer';
export const storeItemStockCard = 'report-stock-card';
export const storeItemStockMutation = 'report-stock-mutation';
export const storeItemExpired = 'report-expired';
export const storeItemStock = 'report-stock';
// REPORT PROFIT
export const storeReportSalesProfit = 'report-selling-profit';
export const storeReportAnalisProfit = 'report-analysis-profit';
// REPORT CASH IN
export const storeReportCashIn = 'report-cash-in';
export const storeReportCashOut = 'report-cash-out';
export const storeReportCashTransfer = 'report-cash-transfer';
// REPORT JOURNAL
export const storeReportListJournal = 'report-journal';
export const storeReportJournalNotBalance = 'report-journal-not-balance';
// REPORT GENERAL LEDGER
export const storeReportGeneralLedger = 'report-general-ledger';
export const storeReportTrialBalance = 'report-trial-balance';
export const storeReportBalanceSheet = 'report-sheet-balance';
// REPORT PROFIT
export const storeReportBalance = 'report-neraca';
export const storeReportIncomeStatement = 'report-profit-and-lost';
// DASHBOARD
export const storeDashboardSale = 'dashboard/sale-total';
export const storeDashboardPurchase = 'dashboard/purchase-total';
export const storeDashboardChart = 'dashboard/chart';
export const storeDashboardPopular = 'dashboard/popular';
export const storeDashboardPrfofit = 'dashboard/profit';
